.container {
    text-align: left;
    margin-top: 3vh;
}

.comment-deco {
    text-decoration-color: white;
}

.card {
    margin-bottom: 1vh;
}

.button {
    margin-top: 1vh;
}

.noPadding {
    padding: unset!important;
}

.indexHeader {
    background-color: #f77f00!important;
    color: white;
    font-weight: bold;
}

.indexCard {
    border: 1px solid black!important;
}

.nick {
    color: black;
}