.folderInfo {

    margin-top: 10px;

}

@media (max-width: 992px){

    .mediaPadding {

        margin-top: 3vh!important;
    
    }

}