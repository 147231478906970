.navbar-dark{

    color:red;
}

.headerLink {

    color: white!important;

}

.headerLink:hover {

    text-decoration: underline!important;

}

.navbar-toggler-icon {

    color: white!important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 255%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")!important;

}

.navbar-toggler {

    border: 1px solid white!important;

}

@media (max-width: 992px){

    .navbar-collapse {

        margin-top: 1vh!important;
    
    }

}
