.toast {

    max-width: 100%!important;

}

.toast:hover {

    border: 1px solid #f77f00;

}

.toastLink:hover {

    text-decoration: none!important;
    cursor: pointer;

}

.toastBody {

    color: #6c757d;

}

.toastImgCol{

    padding-right: unset!important;

}

.toastCol{

    padding-left: unset!important;
}

.imgToast {

    width: 100px!important;
    border-radius: .25rem!important;
    margin: 5px 5px 5px 10px;

}

.toastHeader {

    text-decoration: none!important;

}