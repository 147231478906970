.navFooter {

    background-color: #f77f00;
}

.footerLink {

    color: white;
    text-decoration-color: white;
    margin-left: 3vh;

}

.infoLink {

    color: white;

}

.footerLink:hover {

    color: white;
    text-decoration-color: white!important;
    text-decoration: underline!important;

}

.footerNavbar {

    margin-top: unset!important;

}