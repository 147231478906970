.nick {
    color: black;
}

.nick-div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;
}

.comment-text {

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  

}

.date {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.commentImg {
    background-color: red;
    width: 146px;
    height: 100px;
    object-fit: cover;
}

@media (max-width: 992px) {
    .mediaPadding {
        margin-top: 3vh!important;
    }
}

.verticalCol {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.colLink:hover {
    text-decoration-color: black;
    color: #f77f00;
}

.colLink {
    color: black;
    text-align: unset!important;
}