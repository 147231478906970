.registerForm{
    text-align: left;
    margin-top: 5vh;
    width: 100vw!important;
    margin-bottom: 6vh;
}

.loadingSpinner{
    margin: 5vh;
}

.linkRoute {
    color: #6c757d!important;
}

.linkRoute:hover {
    color: #6c757d!important;
    text-decoration: underline;
    text-decoration-color: #6c757d!important;
}