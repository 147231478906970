.link {
    color: #f77f00;
    margin-right: 10px;
    white-space: nowrap; 
}

.link:hover {
    color: #f77f00;
    text-decoration-color: black;
}

