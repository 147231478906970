.breadcrumbNav {
    border-radius: calc(.25rem - 1px)!important;
}
.breadcrumb-item.active {
    color: #6c757d!important;
}
.breadcrumbImage{

    display: flex;
    justify-self: center!important;
}

.fakeBreadcrumb::after{

        display: inline-block;
        padding-left: .5rem;
        padding-right: .5rem;
        color: #6c757d;
        content: "/";
        box-sizing: border-box;
    
}

.fakeBreadcrumb{

    display: flex;

}