.btn-like {
    background-color: #f77f00!important;
    color: white!important;
}

.btn-like:hover, .btn-like:focus {
    background-color: #d87001!important;
}

.btn-outline-like {
    border-color: #f77f00!important;
    color: #f77f00!important;
}

.btn-outline-like:hover, .btn-outline-like:focus {
    background-color: #f77f00!important;
    color: white!important;
}