.yearLink {
    color: #f77f00;
    font-weight: bold;
    font-size: 30px;
}

.yearLink:hover {
    color: black;
    font-weight: bold;
    font-size: 30px;
}