.container {
    text-align: left;
    margin-top: 4vh;
    margin-bottom: 8vh;
}

.react-date-picker__wrapper {
    border: unset!important;
}

.carouselControl {
    height: 10vh!important;
}

.jumboControl {
    margin: unset!important;
    text-align: left;
    padding-left: 15px!important;
    padding-right: 15px!important;
}

.colControl {
    padding: unset!important
}

.contactCol {
    background-color: #f77f00;
    text-align: left;
    color: white;
    padding: 1vh;
}

.numbersRow {
    background-color: #E9ECEF;
}

.numbersHeader {
    color: #f77f00;
    font-size: 70px;
}

.numbersText {
    font-size: 20px;
}

.orangeButton {
    color: #f77f00!important;
    border-color: #f77f00!important;
}

.imgCarousel {
    object-fit: fill;
    max-width: 100%;
    background-position: center center;
    max-height: 600px;
}

body {
    background-color: #E9ECEF;
}