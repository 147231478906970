@media (max-width: 992px){

    .colPadding {

        margin-top: 2vh!important;
    
    }

}

.rowMargin {

    margin-bottom: 20px;

}

.avatar {

    width: 80px;

}

.commentContent {

    color: black;

}

.commentLink {

        color: #6c757d!important;

}

.replyCol {

    margin-left: 40px;
    flex: 100%!important;

}