.container{

    text-align: left;
    margin-top: 4vh;

}

.profileImg {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .2s ease;
    backface-visibility: hidden;
}

.textProfile {
    background-color: #f77f00;
    color: white;
    font-size: 5px;
    padding: 8px 16px;
}

.profileDiv:hover .profileImg {
    opacity: 0.6;
}

.profileDiv:hover {

    cursor: pointer;

}

.profileDiv:hover .middle {
    opacity: 1;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

@media (max-width: 992px){

    .mediaBlock {

        width: 100%!important;
    
    }

}