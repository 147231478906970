.loginForm{

    text-align: left;
    margin-top: 5vh;

}

.loadingSpinner{

    margin: 5vh;

}

.orText{

    padding: 2vh;

}

.linkRoute {

    color: #6c757d!important;

}

.linkRoute:hover {

    color: #6c757d!important;
    text-decoration: underline;
    text-decoration-color: #6c757d!important;
}