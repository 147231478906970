.colFolder {

    width: 100%!important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.colLink {

    color: black;
    text-align: center;

}

.colLink:hover {

    text-decoration-color: black;
    color: #f77f00;
}

.colLinkText{

    color: black;

}

.colLinkTextImg{

    color: black;

}

.foldersImg {

    background-color: red;
    width: 146px;
    height: 100px;
    object-fit: cover;
    display: inline-block;
    margin-top: 22px;
    margin-bottom: 22px;
}

[class^="icon-"], [class*=" icon-"] {
    display: inline-block;
    width: 100%;
}