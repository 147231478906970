.imageViewRow {
    margin-top: 10px;
}

.cardViewer {

    height: 100%;

}

.viewerImg {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .2s ease;
    backface-visibility: hidden;
}

.text {
    background-color: #f77f00;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
}

.viewerDiv:hover .viewerImg {
    opacity: 0.6;
}

.viewerDiv:hover {

    cursor: pointer;

}

.viewerDiv:hover .middle {
    opacity: 1;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.reportButton {
    vertical-align: bottom!important;
}

@media (max-width: 1199px) {
    .reportButton {
        margin-top: 1vh!important;
    }
}